.puzzle-container {
  gap: 5px;
  margin-bottom: 5px;
}
.card-images {
  width: 235px;
  height: auto;
  min-width: 235px;
  position: relative;
  transition: all 0.3s linear;
  cursor: pointer;
}
.mover {
  width: 235px;
  height: 310px;
  min-width: 235px;
  position: relative;
  transition: all 0.3s linear;
  cursor: pointer;
  z-index: 0;
  background-color: red;
}

/* puzzle stylings */

/* .card-image-2.move1 {
  transform: translateX(120px);
}
.card-image-3.move1 {
  transform: translateX(240px);
}
.mover.move1 {
  transform: translateY(0px);
}  */

.card-image-1.move1 {
  transform: translateX(0px);
}
.card-image-2.move1 {
  transform: translateX(0px);
}
.card-image-3.move1 {
  transform: translateX(0px);
}
.mover.move1 {
  transform: translateX(0px);
}

.card-image-1.move2 {
  transform: translateX(0px);
}
.card-image-2.move2 {
  transform: translateX(-240px);
}
.card-image-3.move2 {
  transform: translateX(0px);
}
.mover.move2 {
  transform: translateX(240px);
}

.card-image-1.move3 {
  transform: translateX(0px);
}
.card-image-2.move3 {
  transform: translateX(-240px);
}
.card-image-3.move3 {
  transform: translateX(-240px);
}
.mover.move3 {
  transform: translateX(480px);
}

.member-shadow {
  box-shadow: 0px 2px 15px rgba(18, 56, 101, 0.08);
  transition: all ease-in-out 0.4s;
  padding: 10px;
}

.member-shadow:hover {
  box-shadow: 0px 2px 15px rgba(2, 40, 86, 0.902);
}

/* .img-icons {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  background-color: rgba(0, 0, 0, 0.35);
  height: 0;
  opacity: 0;
  transition: all ease-in-out 0.6s;
  color: white;
  font-weight: bolder;
}

.img-parent {
  position: relative;
}

.member-shadow:hover .img-icons {
  height: 100%;
  opacity: 1;
} */

.oh {
  height: 600px;
  overflow: auto;
}
