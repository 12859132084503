.footer {
  background-image: url("../../Images/desktop_footer_vector.svg");
  background-position: -110px;
  background-repeat: no-repeat;
  background-color: var(--deep-blue-100);
}
.footer-logo {
  width: 180px !important;
  margin-right: 30px;
  align-self: center;
}

.footer-col {
  padding: 0 !important;
}
.footer-subtitle {
  font-weight: 500;
  margin-bottom: 24px;
  font-size: 20px !important;
  color: var(--white-100);
}
.footer-list {
  font-weight: 300;
  font-size: 16px !important;
  margin: 2px 0 20px;
}
.footer-list a {
  text-decoration: none;
  color: var(--white-100);
}
.footer-list a:hover {
  color: var(--white-100);
}

.footer-social-icons {
  margin-right: 20px;
  font-size: 20px;
}
.footer .form-control::placeholder {
  color: #d2d2d2 !important;
}
.footer .form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--white-100) !important;
}

/* media query */
@media screen and (max-width: 991px) {
  .footer {
    background-image: url("../../Images/mobile_footer_vector.svg");
    background-position: top -15px right;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 575px) {
  .footer-col {
    padding-left: 20px !important;
  }
  .footer-logo {
    transform: translateX(-15px);
  }
}
