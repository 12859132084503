.steps{
    font-size: 18px;
}
.steps-desc{
    font-size: 16px;
}
@media screen and (min-width: 700px){
    .steps{
        font-size: 29px;
    }
    .steps-desc{
        font-size: 22px;
    }
}
