.contact-hero-1 {
  top: -8%;
  right: -4%;
  z-index: 1;
}
.contact-hero-2 {
  top: -16%;
  left: 0%;
  z-index: 1;
}
.contact-hero-3 {
  bottom: -24%;
  left: 40%;
  z-index: 1;
}
.contact-hero-4 {
  left: 2%;
  z-index: 1;
}
.contact-hero-5 {
  top: 50%;
  right: 2%;
  z-index: 1;
}

.outer-circle {
  height: 115%;
  width: 115%;
  border: 2px dashed var(--deep-blue-100);
  opacity: 0.5;
}
.inner-circle {
  height: 80%;
  width: 80%;
  border: 2px dashed var(--green-100);
  opacity: 0.5;
}

.contact-medium-img {
  width: 125px;
  height: 125px;
}

.form-control:focus {
  box-shadow: none !important;
  -webkit-appearance: none !important;
  outline: 0px !important;
  border-color: var(--black-60) !important;
}
.input-style {
  height: 43px !important;
}
textarea {
  resize: none !important;
}
.contact-send-btn {
  width: 201px;
  height: 64px;
  border: none;
  border-radius: 4px;
}
.contact-send-btn-text {
  font-size: 18px;
  line-height: 24px;
}

.contact-fs-24-400 {
  font-size: 24px;
  line-height: 120%;
}
.contact-fs-24-500 {
  font-weight: 500;
  font-size: 20px;
  line-height: 90%;
}
.contact-fs-22-600 {
  font-size: 22px;
  line-height: 27px;
}
.contact-fs-15-600 {
  font-size: 15px;
  line-height: 18px;
}
/* mobile screen */
@media (max-width: 426px) {
  .contact-us-hero-img {
    height: 206px; /* You must set a specified height */
  }
  .contact-fs-24-500 {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
  }
  .contact-send-btn-text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 767.98px) {
  .contact-us-hero-img {
    height: 206px; /* You must set a specified height */
  }
  .contact-fs-24-500 {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
  }
  .contact-send-btn-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .contact-fs-22-600 {
    font-size: 22px;
    line-height: 27px;
  }
}
@media (max-width: 991.98px) {
  .contact-hero-1 {
    top: 9%;
    right: 3%;
  }
  .contact-hero-2 {
    top: -1%;
    left: 5%;
  }
  .contact-hero-3 {
    bottom: -8%;
    left: 40%;
  }
  .contact-hero-4 {
    left: 12%;
  }
  .contact-hero-5 {
    right: 10%;
  }
  .outer-circle {
    height: 90%;
    width: 90%;
    border: 2px dashed var(--deep-blue-100);
  }
  .inner-circle {
    height: 60%;
    width: 60%;
    border: 2px dashed var(--green-100);
  }

  .contact-medium-img {
    width: 85px;
    height: 85px;
  }
}
