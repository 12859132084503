.terms-header {
  font-size: 34px;
  font-weight: 800;
}

.terms-content {
  font-weight: 500;
  line-height: 45px;
  font-size: 18px;
  max-width: 1240px;
}

.terms-content h5 {
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .terms-content {
    font-size: 16px;
  }

  .terms-header {
    font-size: 22px;
  }
}
