.explore-card-container {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}

.explore-card-bg {
  height: 180px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 6px 6px 0px 0px;
}

.explore-fs-20-600 {
  font-size: 20px;
  line-height: 24px;
}

.explore-fs-14-500 {
  height: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.explore-fs-24-700 {
  font-size: 24px;
}

.explore-page .carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}
.explore-page .carousel-indicators .active {
  background-color: var(--green-100) !important;
}
.carousel-indicators [data-bs-target] {
  width: 14px !important;
  height: 14px !important;
  border-radius: 100% !important;
  background-color: var(--green-100) !important ;
}
.carousel-indicators [data-bs-target] {
  width: 14px !important;
  height: 14px !important;
  border-radius: 100% !important;
  background-color: var(--green-100) !important ;
}
.explore-page .carousel-indicators {
  bottom: -10px !important;
}
.explore-page .carousel-indicators button {
  width: 12px !important;
  height: 12px !important;
  margin: 0 5px;
  border-radius: 100% !important;
  background-color: var(--green-100) !important;
}
@media screen and (max-width: 575px) {
  .explore-page .track-desc {
    height: 110px;
  }
}
@media (min-width: 575.98px) and (max-width: 7678px) {
  .explore-page .track-desc {
    height: 140px;
  }
}
@media  (min-width: 1024px) {
  .explore-page .track-desc {
    height: 120px;
  }
}
