
/*
  Navbar start
*/


.navbar {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: fit-content;
  background: rgba(33, 37, 41, 0.02);
  box-shadow: 0px 2px 2px rgba(102, 158, 0, 0.02);
  font-size: 16px;
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4); 
  z-index: 2;
  overflow: hidden;
}


#kodecamp-icon-tablet {
  width: 70px;
  height: 70px;
}

#kodecamp-icon-desktop {
  width: 100px;
  height: 100px;
}

.navbar-links-desktop.active {
  color: var(--blue-100) !important;
}

.navbar-links-desktop {
  color: rgba(33, 37, 41, 0.6);
  line-height: 24px;
  text-decoration: none;
}

.navbar-links-desktop:hover {
  color: rgba(33, 37, 41, 0.6);
}


.btn-login, .btn-enrol {
  line-height: 24px !important;
  padding: 10px !important;
  border-radius: 4px;
}

.btn-login:focus, .btn-enrol:focus {
  box-shadow: none !important;
}

.btn-login {
  color: var(--green-100) !important;
  border: 1px solid var(--green-100) !important;
  width: 100px;
  height: 50px;
}

.btn-enrol {
  border-color: var(--green-100) !important;
  width: 140px;
  height: 50px;
  margin-right: 20px;
  color: var(--white-100) !important;
}

#responsive-navbar-nav {  
  position: absolute;
  right: 0px;
  top: 0px;
  width: 332px !important;
  height: 401px  !important;
  background: #FFFFFF;
  z-index: 5;
}

.navbar a {
  text-decoration: none !important;
}

.navbar-links-mobile {
  color: var(--deep-blue-100);
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
}

#navbar-mobile-login {
  text-decoration: underline !important;
}

.navbar-mobile-line {
  width: 100%;
  opacity: 0.5;
  border-top: 1px solid var(--deep-blue-100) !important;
}

#navbarToggle, #closeCollapseBarIcon {
  cursor: pointer;
}

@media (min-width: 992px) {
  
  .navbar {
    height: 90px;
  }

  #responsive-navbar-nav {
    display: none !important;
  }
  
}


@media (max-width: 575.98px) {
  .btn-enrol {
    text-align: center;
    height: 40px;
    padding-top: 6px !important;
  }

  .btn-enrol {
    width: 130px;
    word-wrap: break-word;
  }

}

/*
  Navbar end
*/
