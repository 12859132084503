.experience {
    font-size: 2em;
}

.women-on-pc-circle {
        border: 2px dotted var(--blue-100);
        z-index: -1;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        position: absolute;
        bottom: 65px;
        right: 45px;
}

.man-and-women-on-pc-circle {
    z-index: -1;
    width: 200px;
    height: 200px;
    position: absolute;
    border: 2px dotted var(--deep-blue-100);
    border-radius: 50%;
    bottom: 8px;
    right: 26px;
}

.man-with-dreads-on-pc-circle {
    z-index: -1;
    width: 250px;
    height: 250px;
    position: absolute;
    border: 2px dotted var(--green-100);
    border-radius: 50%;
    bottom: 10px;
    left: 20px;
}

.lady-on-glasses-circle {
    z-index: -1;
    width: 150px;
    height: 150px;
    position: absolute;
    border: 2px dotted var(--black-100);
    border-radius: 50%;
    top: 5px;
    left: 55px;
}


.mission-vision-container {
    margin-top: 32px;
}

.mission, .vision {
    height: 280px;
    max-width: 470px;
}

.mission-icon {
    width: 48.75px;
    height: 48.75px;
}

.vision-icon {
    width: 59px;
    height: 59px;
}

.polygon-values {
    transform: translate(10px, -210px);
    width: 100%;
    height: 100%;  
}

.polygon-mission, .polygon-vision {
    transform: translate(0px, -330px);
    width: 600px;
}


.vector-about {
    transform: translate(-8px, -80px);
    position: absolute;
}

.culture {
    font-size: 2em;
}

#text-below-culture {
    font-size: 1.25rem;
    line-height: 1.5em;
}

.square-div {
    width: 70px;
    height: 70px;
}

.square-div-text {
    font-size: 3.125em;
}

.text-below-square-div {
    font-size: 1.25em;
}

@media (max-width: 330px) {
    .polygon-values {
        transform: translate(0px, -255px);  
        height: 200px;
        width: 100%;
    }
}

@media (max-width: 359px) {
    .polygon-mission {
        transform: translate(-30px, -330px);
        width: 420px;
    }

    .mission, .vision {
        height: 280px;
    }
}

@media screen and (max-width: 390px) and (min-width: 330px) {
    .polygon-values {
        transform: translate(0px, -230px);  
        height: 200px;
        width: 100%;
    }
}

@media (max-width: 400px) {
    #equity, #equity-text {
        transform: translateX(-25px);
    }

    #novelty, #novelty-text {
        transform: translateX(25px);
    }
}

@media screen and (max-width: 450px) and (min-width: 390px) {
    .polygon-values {
        transform: translate(0px, -205px);  
        width: 100%;
        height: 200px;
    }
}

@media (min-width: 768px) {
    .mission-icon {
        width: 75px;
        height: 75px;
    }

    .vision-icon {
        width: 75px;
        height: 75px;
    }

    .polygon-vision {
        transform: translate(0px, -280px);
    }

    .experience {
        font-size: 2.7em;
    }
}

@media  (max-width: 991.98px) {
    #ambition-text {
        transform: translateX(10px);
    } 
    
    #respect-text {
        transform: translateX(-10px);
    }

}


@media (min-width: 992px) {
    .experience {
        font-size: 3.25em;
    }

    .mission-vision-container {
        margin-top: 120px;
    }

    .polygon-mission {
        width: 112%;
    }

    .polygon-vision {
        margin-top: 25px;
        width: 105%;
    }

    .vision {
        margin-top: 195px;
    }

    .technical-talents {
        width: 70%;
        margin: 0 auto;
    }

    .culture-container {
        background-color: rgba(25,54,77, 0.05);
    }

    .culture {
        font-size: 3em;
    }

    .square-div {
        width: 90px;
        height: 90px;
    }

    .square-div-text {
        font-size: 60px;
    }
}

@media screen and (max-width: 991.98px) and (min-width: 768px) {
    #equity, #novelty {
        margin: 0 auto !important;
    }

    #equity, #equity-text {
        transform: translateX(40px);
    }

    #novelty, #novelty-text {
        transform: translateX(-40px);
    }

    .mission, .vision {
        height: 290px;
    }
}

@media (min-width: 1200px) {
    .polygon-mission {
        width: 100%;
    }
}