.searchbar-fs-20-500 {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.search input {
  border-radius: 4px;
  border: 1px solid rgba(25, 54, 77, 0.6);
  height: 50px;
  background-image: url("../../../Images/search-icon.svg");
  padding-left: 25px;
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 18px;
}
