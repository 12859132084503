.card{
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);   
    z-index: 1;
    transform: rotate(0deg) !important;    
}

.testimony-fs-name{
    line-height: 0.8 !important;
}

.slick-dots li button:before{
    font-size: 16px !important;
    color: var(--green-100) !important;
    padding: 0px;   
}
.slick-dots{
    bottom: -5%;
    left: -2.44%;
}
.image-carousel .slick-next, .image-carousel .slick-prev{
    display: none !important;
}

.testimonial-img{
    width: 80px !important;
    height: 80px !important;
}
.testimony-fs-description{
    font-size: 12px;
}
.testimony-fs-name{
    font-size: 14px;
}
.dotted .card{
    border: 1px dashed var(--green-100) !important;
    transform: rotate(4deg) !important;
    height: 270px;
    width: 320px !important;
}
.not-dotted .card{
    border: 0.1px solid grey  !important;
    transform: rotate(-4deg) !important;
    width: 320px !important;
}
/* .dotted .card:hover {
    transform: rotate(-4deg) !important;
    transform-origin: center !important;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    width: 400px !important;
}
.not-dotted .card:hover{
    border: 0.3px solid gray !important;
    transform: rotate(4deg) !important;
    transform-origin: center !important;
    width: 400px !important;
}  */
.landtesti-fs-12{
        font-size: 16px;
}
.carousel-indicators[data-bs-target] {
    width: 14px !important;
    height: 14px !important;
}
.image-carousel .carousel-indicators {
    width: 0px !important;
    height: 0px !important;
}
.landingpageacc .accordion-button:not(.collapsed)::after {
    content: url("../../Images/landing-card-images/minus.svg") !important;
    background-image: none !important;
    color: white !important;
} 
.landingpageacc .accordion-button.collapsed::after {   
    content: url("../../Images/landing-card-images/add-icon.svg");
    background-image: none !important;
    
}
.landingpageacc .accordion-button{
    color: var(--white-100) !important;
    padding-left: 20px !important;
    font-weight: 500px !important;
}
.landingpageacc .accordion-button:focus {
    box-shadow: none !important;
}
.deepblue100acc .accordion-button {
    background-color: var(--deep-blue-100) !important;
}
.fs-mobile-head{
    font-size: 22px !important;
}

@media (min-width: 1199.98px)  {
    .section-padding{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .not-dotted .card{
        width: 250px !important;
        height: 200px !important;
    }
    .dotted .card{
        width: 250px !important ;
        height: 200px !important ;
        transition: all .3s ease-in-out;
    }
    .testimony-fs-description{
        font-size: 10px;
        transition: all .3s ease-in-out;
    }
    .slick-center .dotted .card{
        transform: translate(-10%, -5%) rotate(4deg) !important;
        width: 330px !important;
        height: 250px !important;
    }
    .slick-center .not-dotted .card{
        transform:  rotate(-4deg) !important;
        width: 330px !important;
        height: 250px !important;
    }
    .slick-center .testimony-fs-description{
        font-size: 14px;
    }
    .testimony-fs-name{
        font-size: 12px;
        margin-bottom: 0.7rem !important;
        transition: all .3s ease-in-out;
    }
    .slick-center .testimony-fs-name{
        font-size: 14px;
        margin-bottom: 0.9rem !important;
    } 
    .testimonial-img{
        width: 50px !important;
        height: 50px !important;
        transition: all .3s ease-in-out;
    }
    .slick-center .testimonial-img {
        width: 80px !important;
        height: 80px !important;
    }
    .slick-slider{
        margin-left: 30px;
    }
    .image-carousel .slick-slider{   
        left: -9% !important;
        margin: 0px auto;
        width: 300px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }    
    .image-carousel .slick-list{   
        margin: 0px auto;
        width: 300px !important;
        margin: 0px -7px;
    }
    .connect-dot-1{
        left: 44.3%;
        top: -23%;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        background-color: var(--green-100);
        opacity: 0.55;
    }
    .connect-dot-2{
        left: 44.4%;
        top: -3%;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        background-color: var(--green-100);
        opacity: 0.40;
    }
    .connect-dot-3{
        left: 44.57%;
        top: 15%;
        border-radius: 50%;
        height: 9px;
        width: 9px;
        background-color: var(--green-100);
        opacity: 0.20;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .section-padding{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    
    .dotted .card{
        width: 380px !important;
        height: 280px !important;
    }
    /* .not-dotted .card{
        width: 350px !important;
        height: 280px !important;
    } */
    .slick-slider{
        margin-left: 70px;
    }
    .testimonial-img{
        width: 100px !important;
        height: 100px !important;
    }
    .testimony-fs-description{
        font-size: 14px;
    }
    .testimony-fs-name{
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-padding{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .dotted .card{
        width: 320px !important;
        height: 280px !important;
    }
    /* .not-dotted .card{
        width: 300px !important;
        height: 250px !important;
    } */
    
    .testimony-fs-description{
        font-size: 14px;
    }
    .testimony-fs-name{
        font-size: 14px;
    }
    .testimonial-img{
        width: 80px !important;
        height: 80px !important;
    }
}

@media (min-width: 425.98px) and (max-width: 767px) {
    .section-padding{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .dotted .card:hover {
        transform: scale(1.05);
        transform-origin: center !important;
        width: 335px !important;       
    }
    .not-dotted .card:hover{
        width: 335px !important;        
    }
    .not-dotted .card{
        width: 320px !important;
        height: 300px !important;
        top: 0% !important;
        left: -15% !important;
    }
    .dotted .card{
        width: 320px !important;
        transition: all .2s ease-in-out !important; 
        height: 300px !important;
        margin-left: 15% !important;
        margin-right: 15% !important;
        text-align: center;
    }
    .testimony-fs-description{
        font-size: 15px;
    }
    .testimonial-img{
        height: 80px !important;
        width: 80px !important;
    }
    .testimony-fs-name{
        font-size: 16px !important;
    }
}
@media (max-width: 425px){
    .landingpageacc .accordion-button{
        font-size: 14px !important;
    }
    .card{
        transition: none !important;   
    }
    .dotted .card{
        width: 260px !important;
        margin-right: auto !important;
        margin-left: auto !important;
        height: 280px !important;
    }
    .not-dotted .card{
        width: 260px !important;
        margin-right: auto !important;
        margin-left: auto !important; 
    }
    .not-dotted .card:hover{
        width: 270px !important;
    }
    .dotted .card:hover {
        width: 270px !important;
    }
    .not-dotted .card .fs-mobile {
        font-size: 14px;
        width: none !important;
    }
    .land-avail{
        text-align: center !important;
        margin: 0px auto;
    }
    .fs-mobile-head{
        font-size: 18px !important;
    }
    .landtesti-fs-12{
        font-size: 14px;
    }
}